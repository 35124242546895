// export const baseSize = 270;

export const sizes = {
  // vertical: {
  //   width: baseSize * 4,
  //   height: baseSize * 5
  // },

  // horizontal: {
  //   width: baseSize * 5,
  //   height: baseSize * 4
  // },

  square: {
    width: 1024,
    height: 1024
  }

  // story: {
  //   width: baseSize * 4.5,
  //   height: baseSize * 8
  // }
};
