import * as Actions from "./constants";

import { call, put, select, takeEvery } from "redux-saga/effects";
import { canvasSelector, itemsSelector } from "./selectors";
import { loadEditorData, uploadTemplate } from "./service";

import { tokenSelector } from "../auth/selectors";

function* loadEditorDataSaga({ payload }) {
  try {
    const res = yield call(loadEditorData, payload);

    console.log(res);

    yield put({ type: Actions.LOAD_EDITOR_DATA_SUCCESS, payload: res.data });
  } catch (e) {
    console.log(e);
    yield put({ type: Actions.LOAD_EDITOR_DATA_ERROR });
  }
}

function* uploadTemplateSaga({ payload }) {
  try {
    // const token =/
    const canvas = yield select(canvasSelector);
    const items = { ...(yield select(itemsSelector)) };
    let formData = new FormData();

    console.log("item", items);

    for (let item of Object.values(items)) {
      if (item && item.type == "image" && item.file) {
        formData.append(`image${item.id}`, item.file);
      }
    }

    if (canvas.file) {
      formData.append(`canvas_image`, canvas.file);
    }

    formData.append("template", JSON.stringify({ canvas, items }));

    const res = yield call(uploadTemplate, {
      form: formData,
      id: payload.id,
      token: payload.token,
    });

    console.log("res", res);

    yield put({
      type: Actions.UPLOAD_TEMPLATE_SUCCESS,
      payload: {
        canvas: res.data.template.canvas,
        items: res.data.template.items,
      },
    });
  } catch (e) {
    console.log(e);
    yield put({ type: Actions.UPLOAD_TEMPLATE_ERROR });
  }
}

export default function* iapSaga() {
  yield takeEvery(Actions.LOAD_EDITOR_DATA, loadEditorDataSaga);
  yield takeEvery(Actions.UPLOAD_TEMPLATE, uploadTemplateSaga);
}
