const fontFamilyOptions = [
  {
    text: "Montserrat",
    value: "Montserrat"
  },
  {
    text: "Manrope",
    value: "Manrope"
  },
  {
    text: "Roboto",
    value: "Roboto"
  },
  {
    text: "Oswald",
    value: "Oswald"
  },
  {
    text: "Merriweather",
    value: "Merriweather"
  },
  {
    text: "Playfair Display",
    value: "Playfair Display"
  },
  {
    text: "Open Sans Condensed",
    value: "Open Sans Condensed"
  },
  {
    text: "Rubik",
    value: "Rubik"
  },
  {
    text: "Lobster",
    value: "Lobster"
  },
  {
    text: "Pacifico",
    value: "Pacifico"
  },
  {
    text: "Exo 2",
    value: "Exo 2"
  },
  {
    text: "Yanone Kaffeesatz",
    value: "Yanone Kaffeesatz"
  },
  {
    text: "Comfortaa",
    value: "Comfortaa"
  },
  {
    text: "Amatic SC",
    value: "Amatic SC"
  },
  {
    text: "Cormorant Garamond",
    value: "Cormorant Garamond"
  },
  {
    text: "Play",
    value: "Play"
  },
  {
    text: "Caveat",
    value: "Caveat"
  },
  {
    text: "IBM Plex Serif",
    value: "IBMPlexSerif"
  },
  {
    text: "Cuprum",
    value: "Cuprum"
  },
  {
    text: "Didact Gothic",
    value: "Didact Gothic"
  },
  {
    text: "Montserrat Alternates",
    value: "Montserrat Alternates"
  },
  {
    text: "Marck Script",
    value: "Marck Script"
  },
  {
    text: "Prata",
    value: "Prata"
  },
  // {
  //   text: "M PLUS 1p",
  //   value: "M PLUS 1p",
  //   italic: false,
  //   bold: true
  // },
  {
    text: "PT Mono",
    value: "PT Mono"
  },
  {
    text: "Philosopher",
    value: "Philosopher"
  },
  {
    text: "Russo One",
    value: "Russo One"
  },
  {
    text: "Neucha",
    value: "Neucha"
  },
  {
    text: "Alice",
    value: "Alice"
  },
  {
    text: "Press Start 2P",
    value: "Press Start 2P"
  },
  {
    text: "Pangolin",
    value: "Pangolin"
  },
  {
    text: "Bad Script",
    value: "Bad Script"
  },
  {
    text: "Cormorant",
    value: "Cormorant"
  },
  {
    text: "Jura",
    value: "Jura"
  },
  {
    text: "Yeseva One",
    value: "Yeseva One"
  },
  {
    text: "Bellota",
    value: "Bellota"
  },
  {
    text: "Marmelad",
    value: "Marmelad"
  },
  {
    text: "Kelly Slab",
    value: "Kelly Slab"
  },
  {
    text: "Forum",
    value: "Forum"
  },
  {
    text: "Anonymous Pro",
    value: "Anonymous Pro"
  },
  {
    text: "Rubik Mono One",
    value: "Rubik Mono One"
  },
  {
    text: "Pattaya",
    value: "Pattaya"
  },
  {
    text: "Gabriela",
    value: "Gabriela"
  },
  {
    text: "Cormorant SC",
    value: "Cormorant SC"
  },
  {
    text: "Prosto One",
    value: "Prosto One"
  },
  {
    text: "Ruslan Display",
    value: "Ruslan Display"
  },
  {
    text: "Underdog",
    value: "Underdog"
  },
  {
    text: "Seymour One",
    value: "Seymour One"
  },
  {
    text: "Stalinist One",
    value: "Stalinist One"
  },
  {
    text: "Rosarivo",
    value: "Rosarivo"
  },
  {
    text: "Adamina",
    value: "Adamina"
  },
  {
    text: "Alegreya",
    value: "Alegreya"
  },
  {
    text: "Alex Brush",
    value: "Alex Brush"
  },
  {
    text: "Bilbo",
    value: "Bilbo"
  },
  {
    text: "Ribeye",
    value: "Ribeye"
  },
  {
    text: "Reenie Beanie",
    value: "Reenie Beanie"
  }
];

// const fontFamilyOptions = [
//   {
//     text: "Playfair Display",
//     value: "Playfair Display"
//   },
//   {
//     text: "Rubik Mono One",
//     value: "Rubik Mono One"
//   },
//   {
//     text: "Jura",
//     value: "Jura"
//   },
//   {
//     text: "Neucha",
//     value: "Neucha"
//   },
//   {
//     text: "Caveat",
//     value: "Caveat"
//   },
//   {
//     text: "Montserrat",
//     value: "Montserrat"
//   },
//   { text: "Comfortaa", value: "Comfortaa" },
//   { text: "Marck Script", value: "Marck Script" },
//   { text: "PT Serif", value: "PT Serif" },
//   { text: "Open Sans Condensed", value: "Open Sans Condensed" },
//   { text: "Cormorant", value: "Cormorant" },
//   { text: "Cormorant Infant", value: "Cormorant Infant" },
//   { text: "Cormorant Unicase", value: "Cormorant Unicase" },
//   { text: "Poiret One", value: "Poiret One" },
//   { text: "Amatic SC", value: "Amatic SC" },
//   { text: "Forum", value: "Forum" },
//   { text: "Yeseva One", value: "Yeseva One" },
//   { text: "Roboto", value: "Roboto" },
//   { text: "Roboto Slab", value: "Roboto Slab" },
//   { text: "Press Start 2P", value: "Press Start 2P" }, // -
//   { text: "Russo One", value: "Russo One" },
//   { text: "Prosto One", value: "Prosto One" },
//   { text: "EB Garamond", value: "EB Garamond" },
//   { text: "Pacifico", value: "Pacifico" },
//   { text: "Sacramento", value: "Sacramento" },
//   { text: "Lovers Quarrel", value: "Lovers Quarrel" },
//   { text: "The Girl Next Door", value: "The Girl Next Door" },
//   { text: "Dynalight", value: "Dynalight" },
//   { text: "Yesteryear", value: "Yesteryear" },
//   { text: "Thasadith", value: "Thasadith" },
//   { text: "Seaweed Script", value: "Seaweed Script" },
//   { text: "Rokkitt", value: "Rokkitt" },
//   { text: "Rancho", value: "Rancho" },
//   { text: "Calligraffitti", value: "Calligraffitti" },
//   { text: "Crafty Girls", value: "Crafty Girls" },
//   { text: "Alex Brush", value: "Alex Brush" }

//   // {
//   //   text: "Neucha",
//   //   value: "Neucha"
//   // },
//   // {
//   //   text: "Fahkwang",
//   //   value: "Fahkwang"
//   // },
//   // {
//   //   text: "Fanwood Text",
//   //   value: "Fanwood Text"
//   // },
//   // {
//   //   text: "Farro",
//   //   value: "Farro"
//   // }
// ];

const fontWeigthOptions = [
  {
    text: "200",
    value: "200"
  },
  {
    text: "300",
    value: "300"
  },
  {
    text: "400",
    value: "400"
  },
  {
    text: "500",
    value: "500"
  },
  {
    text: "600",
    value: "600"
  },
  {
    text: "700",
    value: "700"
  },
  {
    text: "800",
    value: "800"
  },
  {
    text: "900",
    value: "900"
  }
];

const fontStyleOptions = [
  {
    text: "Regular",
    value: "normal"
  },
  {
    text: "Italic",
    value: "italic"
  }
];

export { fontFamilyOptions, fontWeigthOptions, fontStyleOptions };
