import React, { createRef, useEffect, useMemo } from "react";
import {
  StyledContainer,
  StyledContent,
  StyledWrapper
} from "./editor-content-styled";

import EditorContentBackground from "./componets/background";
import EditorContentBlock from "./componets/block";
import EditorContentImage from "./componets/image";
import EditorContentLine from "./componets/line";
import EditorContentText from "./componets/text";
import EditorContentUpload from "./componets/upload";

import { sizes } from "../../../../config/sizes";

const components = {
  text: EditorContentText,
  image: EditorContentImage,
  line: EditorContentLine,
  block: EditorContentBlock,
  upload: EditorContentUpload
};

const EditorContentView = ({
  items,
  scale,
  canvas,
  saved,
  mousePressed,
  activeItem,
  selectedItem,
  contentBaseSize,
  onMouseDown,
  onMouseUp,
  onMouseMove,
  onSelectActiveItem,
  onChangeSelectedItem,
  onChangeDisabledItem,
  onKeyDown,
  onScale
}) => {
  const contentRef = createRef();

  useEffect(() => {
    const scaleW =
      Math.floor(
        ((contentRef.current.offsetWidth - 44) / contentBaseSize.width) * 100
      ) / 100;

    const scaleH =
      Math.floor(
        ((contentRef.current.offsetHeight - 44) / contentBaseSize.height) * 100
      ) / 100;

    const scale = Math.min(scaleH, scaleW);

    onScale(scale <= 1 ? scale : 1);
  }, [contentBaseSize.width]);

  const scalabledCanvasSize = useMemo(() => {
    return {
      width: Math.floor(contentBaseSize.width * scale),
      height: Math.floor(contentBaseSize.height * scale)
    };
  }, [contentBaseSize.width, scale]);

  const renderItem = item => {
    if (item) {
      const ActiveComponent = components[item.type];

      return (
        <ActiveComponent
          {...item}
          scale={scale}
          selected={selectedItem === item.id}
          key={item.id}
          onMouseDown={() => {
            onSelectActiveItem(item.id);
            onChangeSelectedItem(item.id);
          }}
          onChangeDisabledItem={onChangeDisabledItem}
          onClick={() => onChangeSelectedItem(item.id)}
        />
      );
    }
  };

  return (
    <StyledContainer
      ref={contentRef}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseMove={event => mousePressed && activeItem && onMouseMove(event)}
      onKeyDown={onKeyDown}
      tabIndex="0"
    >
      {/* <p>{canvas.countColumn}</p> */}
      {/* <p>{JSON.stringify(canvas.backgroundColor)}</p> */}
      {/* <pre>{JSON.stringify(1 / scale - 1) / (scale * 2)}</pre> */}
      {/* <p>{JSON.stringify(scalabledCanvasSize)}</p> */}
      {/* <p>{JSON.stringify(contentBaseSize)}</p> */}
      <StyledWrapper
        style={{
          // ...scalabledCanvasSize,
          width: scalabledCanvasSize.width + 10,
          height: scalabledCanvasSize.height + 10,
          justifyContent: "center",
          alignItems: "center"
          // backgroundColor: "green"
          // transform: "translateY(-0%) translateX(-20%)"
        }}
      >
        {/* <div style={{ width: 5000, height: 5000, background: "green" }}></div> */}
        <StyledContent
          style={{
            width: contentBaseSize.width,
            height: contentBaseSize.height,
            // backgroundColor: "green"
            transform: `scale(${scale}) translateX(${Math.floor(
              (scalabledCanvasSize.width * (1 / scale - 1)) / (scale * 2)
            )}px)`,
            // translateX(${(contentBaseSize.width *
            //   (1 / scale - 1)) /
            // (scale * 2)} px)
            marginLeft: `-${Math.floor(contentBaseSize.width * (1 - scale))}px`
          }}
        >
          <EditorContentBackground
            inside
            selected={selectedItem === null}
            backgroundColor={canvas.backgroundColor}
            url={canvas.url}
            onMouseDown={() => {
              onSelectActiveItem(null);
              onChangeSelectedItem(null);
            }}
          />

          {Object.values(items).map(renderItem)}
        </StyledContent>
      </StyledWrapper>
    </StyledContainer>
  );
};

export default EditorContentView;
