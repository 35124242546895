import axios from "axios";
import configApi from "../config/api";

const request = axios.create();

request.interceptors.request.use(
  (config) => {
    config.baseURL = configApi.API_ENDPOINT + "/api/admin/iconios";

    // config.headers.common[
    //   "Authorization"
    // ] = `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiYmEwNjA2YjkyMmFhODJlZWRkMGIzNWVhOTdkNThlM2NlZGE0MDY1NTVhZWFhODc1ZmY3OTYwNGVlOGYxNGEyYTc1MzY4MWE3YWM1MmExMWMiLCJpYXQiOjE1OTI2ODM3OTYsIm5iZiI6MTU5MjY4Mzc5NiwiZXhwIjoxNjI0MjE5Nzk2LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.ZNMrwZCLr9PxpQ98PxLlH241AgaY7SpHNsNMMMswU4wcbIQFQUtWWISLuzTtnpw6DQdnR_HOPJWSaHZswF3PoJk9comS21xUMoAK7CoPZj8ioXamVA81lBFWHdYYH95i_jAzMzxX0edVw-Mg23EzFyybupgOKrmRVVnBQvcxWgXcLv-0WN0SMrxHLCm9rDf57-6377fNX_h9D22fHYD5SdvBqGnEVF-CKH0CDsOQBWx__k2vfmIaDYOkfVKgN9GYxJMQXvAjuAffYoWMForbhh7ixj5KmgLII3UPOA89r0osUOY1c9vHXPKEl2tDUTICKzRDCpOZ-bRvAlJ7rk-oRdmKlBG7564wT7Va9WxC7M6zi9Av_MaPZUwFrNlBxcma1J_mGEzSZ9P4rdEwvRtDmZY2GD5No9A4zt4G8zamuLPpc0d1qsRG-aRIHjPEgG9ACM-OXh73zescuaLM6XnV-9WkrRXedZRpLQ8MXZ40YKHOrsjUze0eGKAM4bHsZ4dUjOZ2Rt_Fsp7r8moH6fhvEKcpibODHQWEbcMWoBRf09AESlrYZ6mHiI-IrU6_hVcJOyvIXqYuw9hInW-TBheFw1JlQMTJpfws_fw4xKRjDTNGm_t__mEIJvcWP3yQADLtVW8ynLnnZftjGLpa3LnGfnjbrv5ZElO7bS_IwRn7bxw`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  function (response) {
    let { data } = response;

    return data;
  },
  function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return Promise.reject(error.response.data);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      return Promise.reject(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      return Promise.reject(error);
    }
  }
);

export const setHeader = (token) => {
  console.log(token);
  // const _token =
  //   'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMTQxYzdlNDY0OTBkYzk2MDY3MjllY2ZhZjJiNGEwMWZmZDVhMWJlMTRjZmQ4Mzk1YWFmZmQxYjRlNjU3YTU4NWE1YzJhNTk1NmU4NjE2M2MiLCJpYXQiOjE1ODA3NTA2MjgsIm5iZiI6MTU4MDc1MDYyOCwiZXhwIjoxNjEyMzczMDI4LCJzdWIiOiIyNiIsInNjb3BlcyI6W119.YVI4skx2BStSzm8iVMN2gSPP_UCH2K3bruxncHCuTI-Ge47poESEpBFAY8qENnIpgFfVzc2p2Eum4im257wsTxu4lMcfH7wX5KblOieNZo21yJ1Ucy35pqXIu-M_hF0XQSrSFHVzvZG10RjkgHTfKuQh2YVk6XYsDOk43ZhhnP6e8_hQGW0-RiVbBMxNhsSfay5T7q-g-PmFkS3jbEYcGSWyB117nD6SayAU46DuN6SJ88aQSTQsUBIBsTxOF17guuaqUolmdR_mdk5i5L2BWNHLHjKSMdpDuBBdmf4DTZlQC1DtqrAihSNymDE9feeDl3G_sZvB9Cc18EFJAsq7Sx18Ve0DRTvIlqRKtd_4XNQca13c6MDIPi7ilQCDPFZZDRYdF_anAFnNTD1-3CgXmlglSJgftPpofatxeSU_xIWY7qjQx3msoFxaxxzQ4bhDHgqnxuxohVcgU5w0uDLskZBLs6Wyr8-2Cy-gQ4poWLObGBjWpDNC3b49foNW_n1EwkvBvmBNX4R8nA6zDzD01BC0SAeXH7JzdweGtFUUFuZ9X5ivxWMf1mnr5WY6y5wTgaNU9NxzwQvMIi20RNdF8hZlF1pcOkZWPbeKW0JuAPqCVbRF-oLNnoPP1FHGb_RhjOJ9SXBIw8CW_9J4yfZOd-HDmisvxNbUN-pm22UcbXI';
  request.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

// setHeader(
//   "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiYmEwNjA2YjkyMmFhODJlZWRkMGIzNWVhOTdkNThlM2NlZGE0MDY1NTVhZWFhODc1ZmY3OTYwNGVlOGYxNGEyYTc1MzY4MWE3YWM1MmExMWMiLCJpYXQiOjE1OTI2ODM3OTYsIm5iZiI6MTU5MjY4Mzc5NiwiZXhwIjoxNjI0MjE5Nzk2LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.ZNMrwZCLr9PxpQ98PxLlH241AgaY7SpHNsNMMMswU4wcbIQFQUtWWISLuzTtnpw6DQdnR_HOPJWSaHZswF3PoJk9comS21xUMoAK7CoPZj8ioXamVA81lBFWHdYYH95i_jAzMzxX0edVw-Mg23EzFyybupgOKrmRVVnBQvcxWgXcLv-0WN0SMrxHLCm9rDf57-6377fNX_h9D22fHYD5SdvBqGnEVF-CKH0CDsOQBWx__k2vfmIaDYOkfVKgN9GYxJMQXvAjuAffYoWMForbhh7ixj5KmgLII3UPOA89r0osUOY1c9vHXPKEl2tDUTICKzRDCpOZ-bRvAlJ7rk-oRdmKlBG7564wT7Va9WxC7M6zi9Av_MaPZUwFrNlBxcma1J_mGEzSZ9P4rdEwvRtDmZY2GD5No9A4zt4G8zamuLPpc0d1qsRG-aRIHjPEgG9ACM-OXh73zescuaLM6XnV-9WkrRXedZRpLQ8MXZ40YKHOrsjUze0eGKAM4bHsZ4dUjOZ2Rt_Fsp7r8moH6fhvEKcpibODHQWEbcMWoBRf09AESlrYZ6mHiI-IrU6_hVcJOyvIXqYuw9hInW-TBheFw1JlQMTJpfws_fw4xKRjDTNGm_t__mEIJvcWP3yQADLtVW8ynLnnZftjGLpa3LnGfnjbrv5ZElO7bS_IwRn7bxw"
// );

export const removeHeader = () => {
  request.defaults.headers.common = {};
};

// request.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';

export default request;
