import React, { useEffect, useState, useMemo } from "react";

import EditorContentView from "./editor-content-view";

import { sizes } from "../../../../config/sizes";

function f_beforeunload(e) {
  e.preventDefault();
  e.returnValue = "";
}

const EditorContentContainer = ({
  items,
  selectedItem,
  scale,
  canvas,
  saved,
  initScale,
  saveSaved,
  saveItems,
  saveSelectedItem,
  removeItem
  // onChangeItems,
  // onChangeSelectedItem,
}) => {
  // const [contentSize, setContentSize] = useState({
  //   width: (5400 * 0.22) / 1.5,
  //   height: (1080 * 0.22) / 1.5
  // });

  const [activeItem, setActiveItem] = useState(null);
  const [mousePressed, setMousePressed] = useState(false);
  const [disabledItem, setDisabledItem] = useState(false);
  // const [changed, saveSaved] = useState(false);
  const [moveStartPosition, setMoveStartPosition] = useState({ x: 0, y: 0 });
  const [mousePressPosition, setMousePressPosition] = useState({ x: 0, y: 0 });

  const contentBaseSize = useMemo(() => {
    return {
      width:
        sizes[canvas.type || "horizontal"].width * (canvas.countColumn || 1),
      height: sizes[canvas.type || "horizontal"].height
    };
  }, [canvas]);

  // const scalabledCanvasSize = useMemo(() => {
  //   return {
  //     width: Math.ceil(sizes[canvas.type || "horizontal"].width * scale),
  //     height: Math.ceil(sizes[canvas.type || "horizontal"].height * scale)
  //   };
  // }, [canvas.width, canvas.height, scale]);

  useEffect(() => {
    window.onmouseup = event => {
      setActiveItem(null);
    };

    // var size = [
    //   document.documentElement.clientWidth,
    //   document.documentElement.clientHeight
    // ];

    // window.addEventListener("mousewheel", function(e) {
    //   // if (e.ctrlKey) {
    //   console.log(e);
    //   e.preventDefault();
    //   return 1;
    //   // }
    // });
  }, []);

  useEffect(() => {
    if (!saved) {
      // window.onbeforeunload = function() {
      //   console.log("do something");
      // };
      window.addEventListener("beforeunload", f_beforeunload);
    } else {
      window.removeEventListener("beforeunload", f_beforeunload);
    }
  }, [saved]);

  const handlerBeforeunload = function(e) {
    e.preventDefault();
    e.returnValue = "";
  };

  const handlerMouseDown = event => {
    const position = {
      x: event.clientX,
      y: event.clientY
    };

    setMousePressPosition(position);

    setTimeout(() => {
      setMousePressed(true);
    }, 20);
  };

  const handlerMouseUp = () => {
    setMousePressed(false);

    setMousePressPosition({
      x: 0,
      y: 0
    });
  };

  const handlerMouseMove = event => {
    if (activeItem) {
      const differentPosition = {
        // * (1 - scale)
        x: (event.clientX - mousePressPosition.x) * (1 / scale),
        y: (event.clientY - mousePressPosition.y) * (1 / scale)
      };

      saveItems({
        ...items,
        [activeItem]: {
          ...items[activeItem],
          containerStyle: {
            ...items[activeItem].containerStyle,
            left: Math.floor(moveStartPosition.x + differentPosition.x),
            top: Math.floor(moveStartPosition.y + differentPosition.y)
          }
        }
      });

      // if (saved) {
      //   saveSaved(false);
      // }
    }
  };

  const handlerSelectActiveItem = id => {
    setActiveItem(id);

    if (id !== null) {
      setMoveStartPosition({
        x: items[id].containerStyle.left,
        y: items[id].containerStyle.top
      });
    }
  };

  const handlerKeyDown = event => {
    if (event.keyCode === 8 && selectedItem !== null && !disabledItem) {
      removeItem(selectedItem);
      saveSelectedItem(null);
      // saveSaved(false);
    } else if (event.keyCode === 27 && selectedItem !== null) {
      saveSelectedItem(null);
    }
  };

  return (
    <EditorContentView
      items={items}
      scale={scale}
      canvas={canvas}
      saved={saved}
      mousePressed={mousePressed}
      activeItem={activeItem}
      selectedItem={selectedItem}
      contentBaseSize={contentBaseSize}
      onMouseDown={handlerMouseDown}
      onMouseUp={handlerMouseUp}
      onMouseMove={handlerMouseMove}
      onSelectActiveItem={handlerSelectActiveItem}
      onChangeSelectedItem={saveSelectedItem}
      onChangeDisabledItem={setDisabledItem}
      onKeyDown={handlerKeyDown}
      onScale={initScale}
    />
  );
};

export default EditorContentContainer;
